#navDiv {
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    background-color: #3066be;
    display: flex;
    justify-content: space-evenly;
}

.navText {
    text-decoration: none;
    font-size: 3vw;
    color: #fff;
}

.navSection {
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
}

.navSection:hover {
    align-items: center;
    background-color: #6289c7;
    cursor: pointer;
}
