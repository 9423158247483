#surveyingScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
}

#buildingInWrongPlace {
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    justify-content: space-evenly;
}

#surveyingStockIMG {
    height: 30%;
    width: 30%;
}

#buildingInWrongPlaceText {
    text-align: left;
    color: #306bbe;
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1em;
}

#professionalLandSurveying {
    background-color: #306bbe;
    text-align: left;
    margin-top: 1%;
    margin-bottom: 1%;
}

#professionalLandSurveyingHeader {
    color: #fff;
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1.5em;
}

#professionalLandSurveyingParagraph {
    color: #c0c0c0;
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1em;
}

#completeSurveyingHeader {
    color: #000;
    font-size: 1.5em;
    margin-left: 1%;
    margin-right: 1%;
}

#completeSurveyingList {
    color: #306bbe;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#surveyingBottomBox {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

#surveyingBottomText {
    color: #306bbe;
    font-size: 1em;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
}