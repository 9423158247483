#contactMobileScreen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1%;
}

.mobileHeaderText {
    color: #000;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 1%;
    margin-right: 1%;
}

.mobileSubHeaderText {
    margin-top: 1%;
    color : #000;
    font-size: 1em;
    font-style: italic;
    margin-left: 1%;
    margin-right: 1%;
}

.mobileParagraph {
    margin: 1%;
    color: #3066be;
    font-size: 1em;
}

#contactFormMobile {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.contactInputMobile {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
    margin-right: 1%;
    border: 1px solid #3066be;
}

#contactCommentMobile {
    margin: 1%;
    border: 1px solid #3066be;
}

#submitMobile {
    margin: 1%;
    height: 100%;
    width: 50%;
    justify-self: center;
    align-self: center;
}