#contactScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
}

#contactHeader {
   font-size: 1.5em;
   color: #000;
   margin-left: 1%;
   margin-right: 1%;
   text-align: center; 
}

#textAndForm {
    display: flex;
    justify-content: space-evenly;
}

#contactText {
    color: #306bbe;
    margin-left: 1%;
    margin-right: 1%;
}

.contactInput {
    margin-left: 1%;
    margin-right: 1%;
    width: 90%;
    border: 1px solid #3066be;
}

#contactComment {
    margin-left: 1%;
    margin-right: 1%;
    resize: none;
    border: 1px solid #3066be;
}

#submit {
    margin-left: 1%;
    width: 50%;
}

#surveyorSelect {
    color: #306bbe;
    margin-left: 1%;
    margin-right: 1%;
}

#surveyorSelectText {
    color: #306bbe;
    margin-left: 1%;
    margin-right: 1%;
}