#mobileRootScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#mobileNavDiv {
    position: fixed;
    width: 100%;
    overflow: hidden;
    top: 1%;
    background-color: #3066be;
    display: flex;
    justify-content: space-evenly;
    position: sticky;
}

.mobileNavText {
    font-size: 1.25rem;
    text-align: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    border: 1px solid #000;
}

.mobileLink {
    text-decoration: none;
    color: #fff;
    margin: 1%;
    text-align: center;
}

@media (max-width: 280px) {
    .mobileNavText {
        font-size: 1rem;
    }
}

.mobileNavText:hover {
    background-color: #6289c7;
    cursor: pointer;
}

#mobileCreditsParent {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    border-top: 2px solid black;
    margin-left: 5%;
    margin-right: 5%;
}
