@media (min-width: 800px) {
    #mobileApp {
        display: none;
    }
}

@media (max-width: 799px) {
    #desktopApp {
        display: none;
    }

    p {
        font-size: .9em;
    }
}

.fadedInOutlet {
    animation: fadeInAnimation ease 1500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }