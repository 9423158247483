#bottomNavSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
}

.bottomNav {
    color: #000;
    font-size: 1em;
    text-align: center;
    text-decoration: none;
}

#firstNavMargin {
    margin-left: 5%;
    margin-right: 5%;
}

#secondNavMargin {
    margin-left: 0%;
    margin-right: 5%;
}


