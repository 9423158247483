#aboutScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
}

#ourHistorySection {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 0;
}

#ourHistoryHeader {
    font-size: 1.5em;
    font-weight: bold;
    color: #000;
}

#ourHistoryParagraph {
    text-align: left;
    color: #3066be;
    font-size: 1em;
}

.boldText {
    font-weight: bold;
}

.italicText {
    font-style: italic;
}

#learnMoreAbout {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    font-size: 1em;
    color: #3066be;
    text-align: center;
}

#learnMoreParagraph {
    margin-left: 5%;
    margin-right: 5%;
}

#ourSurveyor {
    background-color: #3066be;
}

#surveyorHeader {
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1.5em;
    color: #fff;
    font-weight: bold;
}

#surveyorText {
    margin-left: 1%;
    margin-right: 1%;
    color: #c0c0c0;
    font-size: 1em;
}

#ourTechnicians {
    font-size: 1em;
    margin-left: 1%;
    margin-right: 1%;
}

#techniciansHeader {
    font-size: 1.5em;
}

#techniciansText {
    color: #3066be;
}