#homepageScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
}

#homepageBanner {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0;
}

.homepageBannerIMG {
    height: 30%;
    width: 30%;
}

#homepageIntroText {
    display: flex;
    width: 100%;
    justify-content: center;
    flex: 1;
}

#introTextHeader {
    text-align: center;
    font-weight: bold;
    color: #000;
    font-size: 1.5em;
}

.introTextBorderBlue {
    background-color: #3066be;
}

.introTextHeaderWhite {
    color: #fff;
    font-size: 1.5em;
    margin-left: 1%;
    margin-top: 1%;
}

.introTextHeaderBlack {
    color: #000;
    font-size: 1.5em;
    margin-top: 1%;
}

.listTextParagraphWhite {
    color: #c0c0c0;
    margin-bottom: 0%;
    margin-top: 0%;
}

#introTextDivider {
    display: flex;
    justify-content: center;
    text-align: center;
}

.introTextColumn {
    flex-basis: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.introTextParagraphBlue {
    color: #3066be;
    font-size: 1em;
    margin-left: 5%;
    margin-right: 5%;
}

#learnMoreSection {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    color: #3066be;
    text-align: center;
}

.learnMoreText {
    margin-left: 5%;
    margin-right: 5%;
}