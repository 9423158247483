#banner {
    display: flex;
    margin-bottom: 1%;
}

#logoIMG {
    max-width: 150px;
    max-height: 150px;
    margin-left: 0%;
}

#bannerText {
    justify-self: center;
    align-self: center;
    text-align: center;
    width: 75%;
    height: 100%;
    font-weight: bold;
}

.bannerTextPara {
    font-size: .8em;
    margin-top: 1%;
    margin-bottom: 0;
    font-weight: normal;
}

#callUsText {
    font-size: 1.25em;
}

#bannerHeading {
    text-align: center;
    font-weight: bolder;
    color: #000;
    font-size: 2em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

#bannerChildRightCorner {
    align-self: start;
    justify-self: start;
    position: absolute;
    right: 1%;
    margin-bottom: 1%;
    max-width: 20%;
}

#facebookGoogleIcons {
    display: flex;
    justify-content: end;
    align-items: start;
}

#facebookIMG {
    max-width: 25px;
    max-height: 25px;
    justify-self: center;
    align-self: center;
}

#googleIMG {
    max-width: 30px;
    max-height: 30px;
    justify-self: center;
    align-self: center;
}

#boldCall {
    font-weight: bold;
    font-size: 1em;
    color: #3066be;
}