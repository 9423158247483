#creditsParent {
    width: 100%;
    background-color: #3066be;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}

#creditsMsg {
    font-size: 1em;
    color: #c0c0c0;
    margin-top: 1%;
}

#creditsChild {
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
}

.creditsLinks {
    font-size: 1em;
    color: #c0c0c0;
    justify-content: space-evenly;
    text-align: center;
    text-decoration: none;
}