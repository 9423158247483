#homepageMobileScreen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1%;
}

#homepageBannerMobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

#homepageHeaderText {
    color: #000;
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 1%;
    margin-right: 1%;
}

#homepageSubHeaderText {
    margin-top: 1%;
    color : #000;
    font-size: 1em;
    font-style: italic;
    margin-left: 1%;
    margin-right: 1%;
}

#homepageLogoMobile {
    max-height: 150px;
    max-width: 150px;
    justify-self: center;
    align-self: center;
}

.textGrayBG {
    text-align: left;
    background-color: #c0c0c0;
}

.blueHeader {
    margin: 1%;
    color: #3066be;
    font-size: 1.25em;
}

.mobileParagraph {
    margin: 1%;
    color: #3066be;
    font-size: 1em;
}

.textWhiteBG {
    background-color: #fff;
    text-align: left;
}